import React from "react";
import { Route, Navigate } from "react-router-dom";
import * as AuthService from "../../services/Auth";

const ProtectedRoute = ({ children }) => {
  //console.log(" AuthService.isAuthenticated()",  AuthService.isAuthenticated());


  return (AuthService.isAuthenticated() === true 
    ? children
    : <Navigate to="/login" />);

};


export default ProtectedRoute;
