import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import WebFont from "webfontloader";

import App from "./App";

import "./styles/index.scss";

WebFont.load({
  custom: {
    families: ["SegoeUI: 300, 400, 600, 700"],
    urls: ["./styles/fonts.scss"],
  },
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
