import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OrderConstant from "../../constants/OrderConstant";
import {
    getAllUserOrders as getAllUserOrdersAction, 
    getFilteredUserOrders as getFilteredUserOrdersAction,
    updateOrder as updateOrderAction,
    addOrder as addOrderAction,
    doneOrder as doneOrderAction,
} from "../../redux/modules/order";
import {
    getDealComments as getDealCommentsAction,
    addComment as addCommentAction,
} from "../../redux/modules/comment";
import { getDealLogs as getDealLogsAction } from "../../redux/modules/logs";
import {
    filterUser as filterUserAction,
    getWorkers as getWorkersAction,
} from "../../redux/modules/login";
import { getUsersByPhone as getUsersByPhoneAction } from "../../redux/modules/users";

import { formatDateDMY, formatDateDMYString, check1DayBefore, formatDateHM } from "../../utilities/date"
import { numberWithCommas } from "../../utilities/numbers";
import { toArrayClearList, inArrayValueByKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiBoard from "../../components/board/UiBoard";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalDealAdd from "../../components/modals/UiModalDealAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./DashBoard.scss";

function DashboardPage(props) {
    const [searchLine, setSearchLine] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [modalDealAddACtive, setModalDealAddActive] = useState(false);

    const clientsTitles = [
        { sort: true, title: "Курьер" },
        { sort: true, title: "ID" },
        { sort: true, title: "Покупатель (получатель)" },
        { sort: true, title: "Оплата" },
        { sort: false, title: "Телефон" },
        { sort: false, title: "Дата\nдоставки" },
        { sort: false, title: "Адрес" },

    ];
    const [tableListOrders, setTableListOrders] = useState([]);
    const newOrder = {
        isNew: true,
        status: 0,
        price: 0,
        payment_type: 0,
        moving_types: 0,
        distance: 1,
        is_pay: 0,
        order_type: 0,
        type: 0,
        composition: "",
        photos: "",
        user_phone: "",
        phone: "",
        name: "",
        date: "",
        time: "",
        order_name: "",
        address_a: "",
        elevator_type_a: 0,
        floor_a: "",
        text: "",
    }

    useEffect(() => {
        let sid = setInterval(() => {
            props.getFilteredUserOrders("deals.status", "<", 4, 300);
        }, 60 * 1000);

        document.querySelector('.header').style = " display: none;";
        document.querySelector('.app-container').style = " padding: 0px !important;";
        if (window.screen.width < 1024) document.querySelector('.menu-left').style = " display: none;";
        return () => {
            clearInterval(sid);
        }
    }, []);

    useEffect(() => {
        if (props.login.user) {
            props.getFilteredUserOrders("deals.status", "<", 4, 300);
            props.getWorkers();
        }
    }, [props.login.user]);

    useEffect(() => {
        if (props.order.ordersList) {
            let arr = [];
            props.order.ordersList.sort((a,b) => { 
                let aa = new Date(a.date);
                let bb = new Date(b.date);
             
                aa.setHours(a.time ? parseInt(a.time.split("-")[0].split(":")[0])  : 0 );
                aa.setMinutes(a.time ? parseInt(a.time.split("-")[0].split(":")[1]) : 0 ); 
                bb.setHours(b.time ?  parseInt(b.time.split("-")[0].split(":")[0]) : 0 );
                bb.setMinutes(b.time ?  parseInt(b.time.split("-")[0].split(":")[1]) : 0 );
               
              
                return  (aa) -  (bb);
            }); 

            props.order.ordersList.map((item) => { 
                    if(
                        //check1DayBefore(item.date) && 
                    
                        item.status < 4
                    )  arr.push([
                        {
                            trColor: item.status == 0 ? "yellow" : item.status == 2 ? "green" : item.status == 3 ? "violet": "blue",
                            text:
                                `${inArrayValueByKey(props.login.usersWorker, "id", item.worker_id) ?
                                    inArrayValueByKey(props.login.usersWorker, "id", item.worker_id).name :
                                    ""
                                }`,
                            icon:  item.worker_id != null &&  item.worker_id != 0 ? "delivery" : null,
                        },
                        { text: `${item.id}` },
                        { text: `${item.name ? item.name : ""} ` },
                        {
                            icon: item.is_pay
                                == 0 ? null : "wallet",
                        },
                        { text: `+${item.phone}` },
                        { text: `${item.date ? formatDateDMYString(item.date) : ""}  ${item.time ? (item.time) : ""}` },
                        { text: item.moving_types == 0 && `${item.address_a ? item.address_a : ""}` },
                    ]) 
               
            })
            setTableListOrders(arr)
        }
    }, [props.order.ordersList]);

    return (
        <div id="page" className="home-page dashboard">
            <section className="home-info ui-grid">
                <div className="home-info-button">
                    <UiButtonColor
                        color="primary"
                        text="Скрыть самовывоз"
                        small={true}
                        onClick={() => {

                        }}
                    />
                </div>
            </section>
            <section className="page-content">
                <UiTableAdaptive
                    adaptive={false}
                    colored={true}
                    titleList={clientsTitles}
                    grid={clientsTitles.length}
                    tableList={tableListOrders}
                    search={searchLine}
                    pageSize={100}
                />
            </section>
        </div>
    );
}

export default connect(
    ({ order, login, comment, logs, user, settings }) => ({
        order,
        login,
        comment,
        logs,
        user,
        settings,
    }),
    {
        getAllUserOrders: getAllUserOrdersAction,
        getFilteredUserOrders: getFilteredUserOrdersAction,
        updateOrder: updateOrderAction,
        addOrder: addOrderAction,
        doneOrder: doneOrderAction,
        getDealComments: getDealCommentsAction,
        addComment: addCommentAction,
        getDealLogs: getDealLogsAction,
        filterUser: filterUserAction,
        getWorkers: getWorkersAction,
        getUsersByPhone: getUsersByPhoneAction,

    }
)(DashboardPage);
