import React, { useState } from "react";
import Select from 'react-select';

import "./UiInputText.scss";

const UiSearchDropDown = (props) => {
    const [value, setValue] = useState("");

    
    return (
        <div className="input-wrap">
            {props.label ? <label>{props.label}</label> : null}

            <Select 
 
                options={props.items} 
                placeholder="Выбрать..."
                noOptionsMessage={() => "Не найдено ..."}
                className={
                    "search-dropdown" +
                    (props.disabled ? " disabled" : "") +
                    (props.info ? " info-active" : "") +
                    (props.small ? " small" : "")
                }
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e.value);
                        setValue(e.value);
                    }
                }}
            />
 

            {props.info ? (
                <div className="input-text-info-wrap">
                    <p className="input-text-info">{props.info}</p>
                </div>
            ) : null}
            {props.error && value === "" ? (
                <p className="input-text-warn">{props.errorText}</p>
            ) : null}
        </div>
    );
};

export default UiSearchDropDown;
