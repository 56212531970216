import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  VerticalBarSeries,
  FlexibleXYPlot,
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  LineMarkSeries,
  Crosshair,
  MarkSeries,
  Hint,
  RadialChart,
} from "react-vis";

import OrderConstant from "../../constants/OrderConstant";

import UiPageText from "../../components/text/UiPageText";
import UiSelectDates from "../../components/select/UiSelectDates";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import { getAllUserStat as getAllUserStatAction } from "../../redux/modules/order";

import { formatDateYMD, formatDatePlusDayYMD } from "../../utilities/date";

import "./StatisticStyle.scss";
import "../../../node_modules/react-vis/dist/style.css";

function StatisticPage(props) {
  const [radialData, setRadialData] = useState([]);
  const [radialHint, setRadialHint] = useState(false);

  const [data, setData] = useState([
    { x: 0, y: 8 },
    { x: 0, y: 5 },
    { x: 0, y: 4 },
    { x: 0, y: 9 },
    { x: 0, y: 1 },
  ]);
  const [data2, setData2] = useState([
    { x: OrderConstant.ORDER_TYPES_NAMES[0], y: 8 },
    { x: OrderConstant.ORDER_TYPES_NAMES[1], y: 5 },
    { x: OrderConstant.ORDER_TYPES_NAMES[2], y: 4 },
    { x: OrderConstant.ORDER_TYPES_NAMES[3], y: 9 },
    { x: OrderConstant.ORDER_TYPES_NAMES[4], y: 1 },

    { x: OrderConstant.ORDER_TYPES_NAMES[6], y: 1 },
  ]);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "" },
  ];
  const ordersTitles = [
    { sort: true, title: "" },
    { sort: true, title: "Готов" },
    { sort: true, title: "В обработке" },
    { sort: true, title: "Всего оформлено" },
  ];

  const [tableListOrders, setTableListOrders] = useState([]);
  const [date1, setDate1] = useState(formatDateYMD(new Date(), "-"));
  const [date2, setDate2] = useState(
    formatDatePlusDayYMD(new Date(), -30, "-")
  );

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    props.getAllUserStat(date2, date1);
  }, [date1, date2]);

  useEffect(() => {
    if (props.order.statistics) {
      let arr = [];
      let arr2 = [];

      arr2.push({
        theta: props.order.statistics.totalPay,
        color: "#059bff",
        name: `Всего оплаченно ${props.order.statistics.totalPay} р`,
      });
      arr2.push({
        theta: props.order.statistics.totalNotPay,
        color: "#ff6384",
        name: `Всего не оплачено ${props.order.statistics.totalNotPay} р`,
      });
      setRadialData(arr2);

      arr.push([
        {
          statusInfo: "Всего оплаченно",
          statusColor: OrderConstant.ORDER_TYPES_COLORS[0],
        },
        { text: `${props.order.statistics.totalPay} р` },
      ]);
      arr.push([
        {
          statusInfo: "Всего заказов",
          statusColor: OrderConstant.ORDER_TYPES_COLORS[1],
        },
        { text: `${props.order.statistics.totalCount}` },
      ]);
      arr.push([
        {
          statusInfo: "Всего не оплачено",
          statusColor: OrderConstant.ORDER_TYPES_COLORS[2],
        },
        { text: `${props.order.statistics.totalNotPay} р` },
      ]);

      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[0],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[0],
        },
        { text: `${props.order.statistics.status0}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[1],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[1],
        },
        { text: `${props.order.statistics.status1}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[2],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[2],
        },
        { text: `${props.order.statistics.status2}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[3],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[3],
        },
        { text: `${props.order.statistics.status3}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[4],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[4],
        },
        { text: `${props.order.statistics.status4}` },
      ]);
      arr.push([
        {
          statusInfo: OrderConstant.ORDER_TYPES_NAMES[6],
          statusColor: OrderConstant.ORDER_TYPES_COLORS[6],
        },
        { text: `${props.order.statistics.status5}` },
      ]);

      setData([
        { x: 0, y: parseInt(props.order.statistics.status0) },
        { x: 1, y: parseInt(props.order.statistics.status1) },
        { x: 2, y: parseInt(props.order.statistics.status2) },
        { x: 3, y: parseInt(props.order.statistics.status3) },
        { x: 4, y: parseInt(props.order.statistics.status4) },
        { x: 5, y: 0 },
        { x: 6, y: parseInt(props.order.statistics.status5) },
      ]);
      setTableListOrders(arr);

      let time = [[], [], [], [], [], [], []];
      let timeTitle = [
        "6:00 - 9:00",
        "9:00 - 12:00",
        "12:00 - 15:00",
        "15:00 - 18:00",
        "18:00 - 21:00",
        "21:00 - 00:00",
        "Остальное",
      ];
      let timeArr = [];
      props.order.statistics.deals.map((item) => {
        let tt = item.time
          ? parseInt(item.time.split("-")[0].split(":")[0])
          : 0;
        let tt2 = item.time
          ? parseInt(item.time.split("-")[1].split(":")[0])
          : 0;
        if (tt >= 6 && tt2 < 9) time[0].push(item);
        else if (tt >= 9 && tt2 < 12) time[1].push(item);
        else if (tt >= 12 && tt2 < 15) time[2].push(item);
        else if (tt >= 15 && tt2 < 18) time[3].push(item);
        else if (tt >= 18 && tt2 < 21) time[4].push(item);
        else if (tt >= 21 && tt2 < 0) time[5].push(item);
        else time[6].push(item);
      });
      time.map((item, index) => {
        timeArr.push([
          {
            statusInfo: timeTitle[index],
            statusColor: OrderConstant.ORDER_TYPES_COLORS[index],
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 4;
              }).length
            } `,
          },
          {
            text: `${
              item.filter((f) => {
                return f.status == 1;
              }).length
            } `,
          },
          { text: `${item.length} ` },
        ]);
      });
      setOrders(timeArr);
    }
  }, [props.order.statistics]);

  return (
    <div id="page" className="statistic-page">
      <section className="statistic-info ui-grid">
        <div className="statistic-info-text">
          <UiPageText title="Статистика" />
        </div>
      </section>
      <section className="page-content">
        <p>Период</p>

        <div className="statistic-wrap statistic-dates-select">
          <UiSelectDates
            onChange={(date) => setDate2(formatDateYMD(new Date(date), "-"))}
            date={new Date(date2)}
          />
          <span>-</span>
          <UiSelectDates
            onChange={(date) => setDate1(formatDateYMD(new Date(date), "-"))}
            date={new Date(date1)}
          />
        </div>

        <div className="statistic-wrap">
          <div className="statistic-orders">
            <div className="statistic-order-graph">
              <XYPlot height={400} width={520}>
                <HorizontalGridLines />
                <VerticalBarSeries data={data} />
                <HorizontalGridLines />
                <XAxis
                  tickLabelAngle={0}
                  tickFormat={(v) => OrderConstant.ORDER_TYPES_NAMES[v]}
                />
                <YAxis />
              </XYPlot>
            </div>
            <div className="statistic-order-graph center">
              <div className="graph-legend">
                <div className="graph-legend-item blue">
                  <span>Всего оплачено</span>
                </div>
                <div className="graph-legend-item pink">
                  <span>Всего не оплачено</span>
                </div>
              </div>

              <RadialChart
                data={radialData}
                innerRadius={100}
                radius={180}
                width={400}
                height={400}
                padAngle={0.04}
                const
                colorType="literal"
                getAngle={(d) => d.theta}
                getLabel={(d) => d.name}
                getColor={(d) => d.color}
                animation={"gentle"}
                className={"donut-chart-example"}
                onValueMouseOver={(v) => setRadialHint(v)}
                onSeriesMouseOut={(v) => setRadialHint(false)}
              >
                {radialHint !== false && (
                  <Hint value={radialHint}>
                    <div className="rv-hint__content">{` ${radialHint.name}`}</div>
                  </Hint>
                )}
              </RadialChart>
            </div>
          </div>
        </div>

        <div className="statistic-info-text">
          <UiPageText title="Заказы с Доставкой в выбранный период" />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={ordersTitles}
            grid={ordersTitles.length}
            tableList={orders}
            search={false}
          />
        </div>

        <div className="statistic-info-text">
          <UiPageText title="Заказы Созданные в выбранный период" />
        </div>

        <div className="statistic-wrap">
          <UiTableAdaptive
            titleList={clientsTitles}
            grid={clientsTitles.length}
            tableList={tableListOrders}
            search={false}
          />
        </div>
      </section>
    </div>
  );
}

export default connect(({ order }) => ({ order }), {
  getAllUserStat: getAllUserStatAction,
})(StatisticPage);
