import {
    storeComment,
    getComments
} from "../../services/Comments";
 
import { inArray } from "../../utilities/array";
 
const moduleName = "comment";
const SET_COMMENTS = `${moduleName}/SET_COMMENTS`;
 
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;
 
const defaultState = {
 
    commentsList: [],
 
    isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_COMMENTS:
            return { ...state, commentsList: payload };

        case SET_API_REQUEST:
            return { ...state, isFetching: payload };

        default:
            return state;
    }
};
 

export const getDealComments = (_id) => async (dispatch, getState) => {
    try {

        let _obj = {
            api_token: getState().login.user.api_token,
            id: _id
        }

        return getComments(_obj).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch({
                type: SET_COMMENTS,
                payload: res.response
            });

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}
 



 
export const addComment = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
        let _obj = {
            api_token: getState().login.user.api_token,
            deal_id: _data.deal_id,
            user_id: getState().login.user.id,
            text: _data.text
        }
       

        return storeComment(_obj).then((res) => {
            console.log(res)
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            if (res.status == 200) {
                dispatch(getDealComments(_data.deal_id))
            }

            return res
        })
    } catch (error) {
        console.error(error);
    }

}


 