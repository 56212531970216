import React from "react";

import "./UiTextTitle.scss";

function UiTextTitle(props) {
  return (
    <h3
      className={
        (props.adaptive ? "adaptive" : "") +
        (props.color === "blue" ? " text-blue" : "") +
        (props.size === 22 ? " text-22" : "") + 
        (props.size === 32 ? " text-32" : "") + 
        (props.size === 40 ? " text-40" : "")
      }
    >
      {props.text}
    </h3>
  );
}

export default UiTextTitle;
