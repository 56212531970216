import { 
    getLogs
} from "../../services/Logs";
 
 
const moduleName = "logs";
const SET_LOGS = `${moduleName}/SET_LOGS`;
 
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;
 
const defaultState = {
    logsList: [],
    isFetching: false,
};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LOGS:
            return { ...state, logsList: payload };

        case SET_API_REQUEST:
            return { ...state, isFetching: payload };

        default:
            return state;
    }
};
 

export const getDealLogs = (_id) => async (dispatch, getState) => {
    try {

        let _obj = {
            api_token: getState().login.user.api_token,
            id: _id
        }

        return getLogs(_obj).then((res) => {
            console.log(res);
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            dispatch({
                type: SET_LOGS,
                payload: res.response
            });

            return res.suggestions;
        })
    } catch (error) {
        console.error(error);
    }

}
 

 
 