import React from "react";
import { connect } from "react-redux";
import { changeConfirmState as changeConfirmStateAction } from "../../redux/modules/login";

import { ReactComponent as SvgInfo } from "../../assets/images/ui/info.svg";

import UiButtonColor from "../button/UiButtonColor";
import UiModal from "./UiModal";

import "./UiModal.scss";

function UiDialog(props) {
  var dialogText = props.textList.map((item, index) => {
    return (
      <div key={index} className="dialog-text-item">
        <p className="dialog-text-item-title">{item.title}</p>
        {item.text !== "" ? (
          <p className="dialog-text-item-text">{item.text}</p>
        ) : null}
      </div>
    );
  });

  return (
    <UiModal name="alert" show={props.login.confirmShowed}>
      <div className="alert-card">
        <div className="alert-card-title">
          <h3>{props.login.alertTitle}</h3>
        </div>
        <SvgInfo className="dialog-icon" />
        <div className="dialog-text">{props.login.alertText}</div>
        <div className="dialog-buttons ui-grid">
          <UiButtonColor
            onClick={() => {
              props.login.confirmFunc();
              props.changeConfirmState(false);
            }}
            text={props.okText}
            small
          />
          <UiButtonColor
            onClick={() => props.changeConfirmState(false)}
            text={props.noText}
            small
          />
        </div>
      </div>
    </UiModal>
  );
}

export default connect(({ login }) => ({ login }), {
  changeConfirmState: changeConfirmStateAction,
})(UiDialog);
