import Env from "../../services/Env";
import { getList, makeMaterial, updateMaterial } from "../../services/Material";

import { storeData, retrieveData, safelyParseJSON } from "../../services/Storage";

const moduleName = "material";

const SET_LIST = `${moduleName}/SET_LIST`;
const SET_INVITES_LIST = `${moduleName}/SET_INVITES_LIST`;
const SET_INVITE_COUNT = `${moduleName}/SET_INVITE_COUNT`;
const SET_ERROR = `${moduleName}/SET_ERROR`;
const SET_ERROR_TYPE = `${moduleName}/SET_ERROR_TYPE`;
const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;

const defaultState = {
    list: [],
    invites: [],
    error: false,
    errorType: 3,
    inviteCount: 10,
    isFetching: false,

}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_LIST:
            return { ...state, list: payload }
        case SET_INVITES_LIST:
            return { ...state, invites: payload }
        case SET_INVITE_COUNT:
            return { ...state, inviteCount: payload }
        case SET_ERROR:
            return { ...state, error: payload }
        case SET_ERROR_TYPE:
            return { ...state, errorType: payload }

        case SET_API_REQUEST:
            console.log('fetching API request', payload)
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}


export const setList = (value) => async (dispatch) => {
    try {
        dispatch({
            type: SET_LIST,
            payload: value
        });
    } catch (error) {
        console.error(error);
    }
}



export const getMaterialList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });


        return getList().then((res) => {
            console.log(res)
            if (res.response) {
                dispatch({
                    type: SET_LIST,
                    payload: res.response
                });
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const addMaterial = ( _data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
 
        return makeMaterial(getState().login.user.api_token,  _data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getMaterialList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}


export const update = (_data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SET_API_REQUEST,
            payload: true
        });
 
        return updateMaterial(getState().login.user.api_token,  _data).then((res) => {
            console.log(res)
            if (res.response) {
                dispatch(getMaterialList());
            }
            dispatch({
                type: SET_API_REQUEST,
                payload: false
            });
            return res.response
        })
    } catch (error) {
        console.error(error);
    }

}

 