import React, { Component, useState } from "react";

import "./UiSelect.scss";

const UiSelect = (props) => {
  const [activeValue, selectValue] = useState("");

  function changeValue(value) {
    let newVal = value;
    selectValue(newVal);
    if (props.onChange) props.onChange(newVal);
  }

  if (props.optionList) {
    var optionList = props.optionList.map((item, index) => {
      return (
        <option key={index} value={item.value}>
          {item.text}
        </option>
      );
    });
  }

  return (
    <div className="select-wrap">
      {props.label ? <label>{props.label}</label> : null}
      <select
        className="select"
        onChange={(e) => changeValue(e.target.value)}
        value={activeValue}
      >
        {optionList}
      </select>
    </div>
  );
};
export default UiSelect;
