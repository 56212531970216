import ENV from './Env.js';
 

export const storeComment = (_data) => {
    console.log(_data);
    
    return fetch(`${ENV.API_URL}/comment/store`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}
 

export const getComments = (_data) => {
    return fetch(`${ENV.API_URL}/comment/show/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

 
 
 