import ENV from './Env.js';
 
export const getLogs = (_data) => {
    return fetch(`${ENV.API_URL}/logs/show/${_data.id}`, {
        method: 'POST',
        headers: ENV.getHeaders(),
        body: JSON.stringify(_data)
    }).then(function (response) {
        return response.json();
    });

}

 
 
 