import React from "react";

import UiLogoTitle from "../../components/text/UiLogoTitle";

import "./UiHeaderBg.scss";

function UiHeaderBg(props) {
  return (
    <header className="header-wrap">
      <div
        className={
          "header-inner" + (props.keyboardActive ? " keyboard-active" : "")
        }
      >
        <UiLogoTitle />
      </div>
    </header>
  );
}

export default UiHeaderBg;
