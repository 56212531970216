import login from './login';
import users from './users';
import storage from './storage';
import material from './material';
import category from './category';
import order from './order';
import comment from './comment';
import logs from './logs';
import settings from './settings';

export default {
    users,
    login,
    storage,
    material,
    order,
    comment,
    logs,
    settings,
    category
}