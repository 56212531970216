import React from "react";

import "./UiText.scss";

const UiText = ({ adaptive, children, color, size }) => {
  return (
    <p className={(adaptive ? "adaptive " : "") + color + " " + size}>
      {children}
    </p>
  );
};

export default UiText;
