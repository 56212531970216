import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from 'react-to-print';


import OrderConstant from "../../constants/OrderConstant";
import {
    getAllUserOrders as getAllUserOrdersAction,
    updateOrder as updateOrderAction,
    addOrder as addOrderAction,
    doneOrder as doneOrderAction,
} from "../../redux/modules/order";
import {
    retrieveData
} from "../../services/Storage";

import { getUsersByPhone as getUsersByPhoneAction } from "../../redux/modules/users";

import { formatDateDMY, formatDateHM } from "../../utilities/date"
import { numberWithCommas } from "../../utilities/numbers";
import { toArrayClearList, inArrayValueByKey } from "../../utilities/array";

import UiCheckPrint from "../../components/print/UiCheckPrint";

function PrintCheckPage(props) {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const componentRef = useRef();

    useEffect(() => {
        let data = retrieveData("SET_ORDER");
        setData(data);
        console.log("SFA", data)

        setTimeout(() => {

        }, 1000)
    }, []);



    return (
        <div id="page" className="home-page">
            <ReactToPrint
                trigger={() => <button className="button button-blue button-primary small" style={{ width: 200, marginBottom: 20, color: "#fff" }}>Печать чека!</button>}
                content={() => componentRef.current}
            />


            {data ? <UiCheckPrint ref={componentRef} data={data} /> : null}
        </div>
    );
}

export default connect(
    ({ order, login, comment, logs, user, settings }) => ({
        order,
        login,
        comment,
        logs,
        user,
        settings,
    }),
    {
        getAllUserOrders: getAllUserOrdersAction,
        updateOrder: updateOrderAction,
        addOrder: addOrderAction,
        doneOrder: doneOrderAction,
        getUsersByPhone: getUsersByPhoneAction,

    }
)(PrintCheckPage);
