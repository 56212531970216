import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSettingList as getSettingListAction,
  updateByKey as updateByKeyAction,
} from "../../redux/modules/settings";

import OrderConstant from "../../constants/OrderConstant";

import UiPageText from "../../components/text/UiPageText";
import UiInputText from "../../components/inputs/UiInputText";

import "./SettingStyle.scss";

function SettingPage(props) {
 
  useEffect(() => {
    if (props.login.user) props.getSettingList();
  }, [props.login.user]);

  
  let list = props.settings.list.map(
    (item, index) => {
      return (
        <UiInputText
          key={index}
          placeholder={item.value}
          label={`${item.description} (Если стоить 0 значит ВЫКЛЮЧЕНО )`}
          onBlur={(val) => { 
            if(val != '') props.updateByKey(item.title, val);
          }}
        />
      );
    }
  );

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Настройки" />
        </div>
      </section>
      {console.log(props.settings.pricesObjects)}
      <section className="page-content">
        {list}
      </section>
    </div>
  );
}

export default connect(({ login, settings }) => ({ login, settings }), {
  getSettingList: getSettingListAction,
  updateByKey: updateByKeyAction,
})(SettingPage);
