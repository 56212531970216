import React from "react";

import "./UiCardUser.scss";

const UiCardUser = (props) => {
  return (
    <div className="card-user">
      <div className="card-user-wrap">
        <p>
          ID {props.id} <br />
          <b>{props.name}</b>
        </p>
        <p>
          <b>Рейтинг:</b> {props.rating} &#11088;{" "}
        </p>
      </div>
      <div className="card-user-wrap">
        <p>
          Телефон: <a href={`tel:+${props.phone}`}>+{props.phone}</a>
        </p>
      </div>
      <div className="card-user-wrap">
        <p>
          <b>Активный заказ:</b> {props.userDeal ? `#${props.userDeal}` : "Нет"}{" "}
        </p>
      </div>
      {props.deal.id != props.userDeal &&  props.userDeal == null  ? (
        <div className="card-user-control">


          <button
            className="card-user-control-button"
            onClick={props.onAddWorker}
          >
            Назначить на заказ
          </button>

        </div>
      ) : null}
      {props.deal.id == props.userDeal ? 
       <div className="card-user-control">
            <a
              className="card-user-control-button"
              target="_blank"
              href={props.href}
            >
              Отправить данные по заказу
            </a>
      </div> : null}
    </div>
  );
};

export default UiCardUser;
