import Env from "../../services/Env";
import {
   getUsers,
   updateWorkerUser,
   addWorkerUser,
   filter,
} from "../../services/User";
import {makeImage} from "../../services/Image";

const moduleName = "user";

const SET_USER = `${moduleName}/SET_USER`;
const SET_USERS = `${moduleName}/SET_USERS`;
const SET_WORKERS = `${moduleName}/SET_WORKERS`;
const SET_USER_INFO = `${moduleName}/SET_USER_INFO`;


const SET_API_REQUEST = `${moduleName}/SET_API_REQUEST`;
const REGISTER = `${moduleName}/REGISTER`;

const defaultState = {
    usersList: [],
    usersWorker: [],
    tablesList: [],
}

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SET_USER:
            return { ...state, user: payload }
        case SET_USERS:
            return { ...state, usersList: payload }
        case SET_WORKERS:
            return { ...state, usersWorker: payload }

 
        case SET_USER_INFO:
            return { ...state, userInfo: payload }
 
        case SET_API_REQUEST:
            console.log('fetching API request', payload)
            return { ...state, isFetching: payload }
        case REGISTER:
            return { ...state, isFetching: payload }

        default:
            return state;
    }

}
 

export const getUsersList = () => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            return filter(getState().login.user.api_token, "is_worker", "0" ).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    let _data = [];
                    res.response.map((item) => {if(item.type == 3) _data.push(item) })
                    dispatch({
                        type: SET_USERS,
                        payload: _data
                    });
                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const getManagersList = () => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            return filter(getState().login.user.api_token, "is_worker", "0" ).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    let _data = [];
                    res.response.map((item) => {if(item.type == 0 || item.type == 1 || item.type == 2) _data.push(item) })
                    dispatch({
                        type: SET_USERS,
                        payload: _data
                    });
                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }
}


export const getWorkersList = () => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            return filter(getState().login.user.api_token, "is_worker", "1" ).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    let _data = [];
                    res.response.map((item) => { _data.push(item) })
                    dispatch({
                        type: SET_USERS,
                        payload: _data
                    });
                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }
}




export const getUsersByPhone = (phone) => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            return filter(getState().login.user.api_token, "phone",  phone ).then((res) => {
                console.log(res)
                if (res.status == 200) {
                   if(res.response.length > 0) return res.response[0]; return null;
                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return null;
            })
        } catch (error) {
            console.error(error);
        }
    }
}


export const addManager = (_data) => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            let data = _data;
            data.api_token = getState().login.user.api_token;
            data.id = getState().login.user.id;
            data.is_worker = 0;
            return addWorkerUser(data).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    if(data.image) {  
                        data.image.map((itemImage) => {
                            makeImage({
                                api_token: getState().login.user.api_token,
                                name: itemImage,
                                user_id: res.response.id,
                                material_id: null
                            }).then(() => {
                                dispatch(getManagersList()); 
                            })
                        })
                       
                    } else {
                        dispatch(getManagersList()); 
                    }

                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const addWorker = (_data) => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            let data = _data;
            data.api_token = getState().login.user.api_token;
            data.id = getState().login.user.id;
            data.is_worker = 1;
            data.type = 3;
            return addWorkerUser(data).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    if(data.image) {  
                        data.image.map((itemImage) => {
                            makeImage({
                                api_token: getState().login.user.api_token,
                                name: itemImage,
                                user_id: res.response.id,
                                material_id: null
                            }).then(() => {
                                dispatch(getWorkers()); 
                            })
                        })
                       
                    } else {
                        dispatch(getWorkers()); 
                    }

                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const editWorker = (_data) => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            let data = _data;
            data.api_token = getState().login.user.api_token;
            data.author_id = getState().login.user.id;
      
            return updateWorkerUser(data).then((res) => {
                console.log(res)
                if (res.status == 200) {
                   
                    if(data.image) {  
                        data.image.map((itemImage) => {
                            makeImage({
                                api_token: getState().login.user.api_token,
                                name: itemImage,
                                user_id: res.response.id,
                                material_id: null
                            }).then(() => {
                                dispatch(getWorkers()); 
                            })
                        })
                       
                    } else {
                        dispatch(getWorkers()); 
                    }

                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }
}




export const getWorkers = () => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });
            return filter(getState().login.user.api_token, "is_worker", 1 ).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    let _data = res.response;
                    dispatch({
                        type: SET_WORKERS,
                        payload: _data
                    });
                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }
}

export const filterUser = (_key, _filter) => async (dispatch, getState) => {
    if (getState().login.user.api_token) {
        try {
            dispatch({
                type: SET_API_REQUEST,
                payload: true
            });


            return filter(getState().login.user.api_token, _key, _filter ).then((res) => {
                console.log(res)
                if (res.status == 200) {
                    let _data = res.response;
                    dispatch({
                        type: SET_USERS,
                        payload: _data
                    });
                }
                dispatch({
                    type: SET_API_REQUEST,
                    payload: false
                });
                return res.response
            })
        } catch (error) {
            console.error(error);
        }
    }


}







 