import React, { useEffect, useState } from "react";

import UiButtonColor from "../button/UiButtonColor";
import UiInputText from "../inputs/UiInputText";
import UiSelect from "../select/UiSelect";
import UiModal from "./UiModal";

import "./UiModalNotification.scss";

function UiModalEditComposition(props) {
  const [activeOption, setOption] = useState(null);

  const [item, setItem] = useState({
    custom_title: null,
    custom_text: null,
    custom_price: null,
  });

  useEffect(() => {
    if (props.item) {
      setItem(props.item);
    }
  }, [props.modalOpen, props.item]);

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  return (
    <UiModal name="modal-notification" show={props.modalOpen}>
      <div className="modal-container">
        <h3>Редактировать вариант</h3>
        <button className="modal-close" onClick={props.cancelClick}>
          Х
        </button>
        <div className="modal-add-form">
          <UiInputText
            label="Название варианта"
            value={item.custom_title}
            small
            onChange={(val) => setItem({ ...item, custom_title: val })}
          />

          <UiInputText
            label="Описание варианта"
            value={item.custom_text}
            small
            onChange={(val) => setItem({ ...item, custom_text: val })}
          />

          <UiInputText
            label="Цена"
            value={item.custom_price}
            small
            onChange={(val) => setItem({ ...item, custom_price: val })}
          />

          {item.image && (
            <img
              src={item.image}
              style={{ width: "100px", height: "100px", borderRadius: "4px" }}
            />
          )}
          <UiInputText
            label="Фотография"
            type="file"
            onChange={(e) => {
              getBase64(e.target.files[e.target.files.length - 1], (result) => {
                let arr = [];
                arr.push(result);
                props.cancelClick();
                props.onChangeImage(arr);
              });
            }}
          />

          <UiButtonColor text="Редактировать" onClick={() => props.onSave(item)} />
        </div>
      </div>
    </UiModal>
  );
}

export default UiModalEditComposition;
