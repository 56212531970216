import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import OrderConstant from "../../constants/OrderConstant";
import {
  getAllUserOrders as getAllUserOrdersAction,
  getFilteredUserOrders as getFilteredUserOrdersAction,
  updateOrder as updateOrderAction,
  addOrder as addOrderAction,
  doneOrder as doneOrderAction,
  setOrder as setOrderAction,
  getComposition as getCompositionAction,
  addSpecialComposition as addSpecialCompositionAction,
  destroySpecialComposition as destroySpecialCompositionAction,

  setLoaderState as setLoaderStateAction
} from "../../redux/modules/order";
import {
  getDealComments as getDealCommentsAction,
  addComment as addCommentAction,
} from "../../redux/modules/comment";
import { getDealLogs as getDealLogsAction } from "../../redux/modules/logs";
import {
  filterUser as filterUserAction,
  getWorkers as getWorkersAction,
  getManager as getManagerAction,
  editUserData as editUserDataAction
} from "../../redux/modules/login";
import { getUsersByPhone as getUsersByPhoneAction } from "../../redux/modules/users";
import {
  getStorageListByIdArray as getStorageListByIdArrayAction,
  getStorageList as getStorageListAction
} from "../../redux/modules/storage";

import { formatDateDMY, formatDateHM } from "../../utilities/date"
import { numberWithCommas } from "../../utilities/numbers";
import { toArrayClearList, inArrayValueByKey } from "../../utilities/array";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiBoard from "../../components/board/UiBoard";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalDealAdd from "../../components/modals/UiModalDealAdd";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./HomeStyle.scss";

function HomePage(props) {
  const navigate = useNavigate();
  const [searchLine, setSearchLine] = useState(null);
  const [searchLine2, setSearchLine2] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedCompositionData, setSelectedCompositionData] = useState(null);
  const [modalDealAddACtive, setModalDealAddActive] = useState(false);

  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "Дата и\nвремя" },
    { sort: true, title: "Номер" },
    { sort: false, title: "Покупатель" },
    { sort: false, title: "Состав" },
    { sort: true, title: "Статус\nзаказа" },
    { sort: true, title: "Сумма" },
    { sort: false, title: "Оплата" },

    { sort: false, title: "Курьер" },
    { sort: false, title: "Дата\nдоставки" },
    { sort: false, title: "Адрес\nдоставки" },
    { sort: false, title: "Комментарий" },
    { sort: false, title: "Тип\nоплаты" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);


  useEffect(() => {
    if (props.login.user) {
      props.setLoaderState(true);
      props.getAllUserOrders();
      props.getWorkers();
      props.getManager();
      props.getStorageList();
    }
  }, [props.login.user]);

  useEffect(() => {
    if (props.order.ordersList) {
      let arr = [];
      props.order.ordersList.map((item) => {
        if(props.login.user.is_worker == 0 || (props.login.user.is_worker == 1 && props.login.user.id == item.worker_id && item.status < 4)) { 
          arr.push([
            {
              button: "edit", openClick: () => {
                if (props.login.user.type == 0 || props.login.user.type == 1) {
                  openDeal(item)
                }
              }
            },
            { text: `${formatDateDMY(item.created_at)}  ${formatDateHM(item.created_at)}` },
            { text: `${item.id}` },
            { text: `${item.name ? item.name : ""} +${item.phone}` },
            { text: `${item.values}` },
            props.login.user.is_worker == 0 ? 
            {
              statusInfo: OrderConstant.ORDER_TYPES_NAMES[item.status],
              statusColor: OrderConstant.ORDER_TYPES_COLORS[item.status],
              statusList: OrderConstant.ORDER_TYPES_NAMES,
              onStatusChange: (val) => {
                let _item = item;
                _item.status = val;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
              }
            } : 
            {
              button: "edit",
              editText: OrderConstant.ORDER_TYPES_MANAGER_NAMES[1],
              
              openClick: (val) => {
                let _item = item;
                _item.status = 4;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
               
              }
            }
            
            ,
  
            { text: `${numberWithCommas(item.price)} ₽` },
            {
  
              payStatus: item.is_pay
                == 0 ? "wait" : "done",
              statusPayList: OrderConstant.ORDER_IS_PAY,
              onStatusPayChange: (val) => {
                let _item = item;
                _item.is_pay = val;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
              }
            },
            {
              curInfo:
                `${inArrayValueByKey(props.login.usersWorker, "id", item.worker_id) ?
                  inArrayValueByKey(props.login.usersWorker, "id", item.worker_id).name :
                  "-"}`,
              curList: toArrayClearList(props.login.usersWorker, "name"),
              onCurChange: (val) => {
                let _item = item;
                _item.worker_id = val;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
              }
  
            },
            { text: item.moving_types == 0 ? `${formatDateDMY(item.date)}  ${(item.time)}` : 'Самовывоз' },
            { text: `${item.address_a ? item.address_a : '-'}` },
            { text: item.text ? item.text : "-" },
            { text: OrderConstant.ORDER_PAYMENTS_TYPES_NAMES[item.payment_type] },
          ])
        }
     
      })
      setTableListOrders(arr);
      props.setLoaderState(false);
    }
  }, [props.order.ordersList]);

  function searchNum(line){
   if(line.length > 0) props.getFilteredUserOrders("deals.id", "=", line, 100); else props.getAllUserOrders();
  }
  function searchPhone(line){
    if(line.length > 0) props.getFilteredUserOrders("users.phone", "LIKE",  `'%${line}%'`, 100);  else props.getAllUserOrders();
  }


  function openDeal(item) {
    item.worker_name = inArrayValueByKey(props.login.usersWorker, "id", item.worker_id) ?
      inArrayValueByKey(props.login.usersWorker, "id", item.worker_id).name :
      "";
    item.manager_name = inArrayValueByKey(props.login.usersManager, "id", item.manager_id) ?
      inArrayValueByKey(props.login.usersManager, "id", item.manager_id).name :
      "";
    props.getStorageListByIdArray(item.composition);
    item.isNew = false;
    props.setOrder(item);
    props.getComposition(item.id);
    setModalDealAddActive(true);
    console.log(item)
    setSelectedData(item);
  }

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Заказы" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Новый заказ"
            small={true}
            onClick={() => {
              if (props.login.user.type == 0 || props.login.user.type == 1) {
                props.getComposition(0);
                setModalDealAddActive(true)
                setSelectedData(null)
              }
            }}
          />

          <UiButtonColor
            color="orange"
            text="Экран доставки"
            small={true}
            onClick={() => window.open('/dashboard', '_blank')}
          />
        </div>


      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch 
            placeholder="Поиск: Номер заказа "
            onChange={(val) => searchNum(val)}
          />
          <UiInputSearch 
            placeholder="Поиск: Номер телефон"
            onChange={(val) => searchPhone(val)}
          />
        </div>
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders} 
        />


      </section>
      {/* modal deal add */}
      <UiModalDealAdd
        managers={props.login.usersManager}
        curriers={props.login.usersWorker}
        materials={props.storage.compositionList}
        storage={props.storage.list}
        data={selectedData}
        compositionData={props.order.compositionOrder}
        modalActive={modalDealAddACtive}
        modalClose={() => setModalDealAddActive(false)}
        findUserEvent={props.getUsersByPhone}
        prices={props.settings.prices}

        onCreate={(data) => {
          console.log(data)
          props.addOrder(data).then((res) => {
            // setModalDealAddActive(false);
            alert("Заказ создан")
            openDeal(res.response);
          });
        }}
        onCreateSpecial={(data, order) => {
          if (data.order_id) props.addSpecialComposition(data); else {
            props.addOrder(order).then((res) => {
              if (res.response) {
                data.order_id = res.response.id;
                props.setOrder(res.response);
                props.getComposition(res.response.id);
                setSelectedData(res.response);
                props.addSpecialComposition(data);
              }
            });
          }
        }}
        deleteComposition={(_id) => {
          props.destroySpecialComposition(_id).then(() => {
            props.getComposition(selectedData.id);
          });
        }}
        update={(data) => {
          props.updateOrder(data, OrderConstant.UPDATE_INFO_CHANGE_INFO).then(() => {
            // setModalDealAddActive(false);
            alert("Данные обновлены")
          });
        }}

        editUser={(data) => {
          props.editUserData(data).then(() => {
            // setModalDealAddActive(false);
            props.getAllUserOrders();
          });
        }}
      />
    </div>
  );
}

export default connect(
  ({ order, login, comment, logs, user, storage, settings }) => ({
    order,
    login,
    comment,
    logs,
    user,
    settings,
    storage
  }),
  {
    setLoaderState: setLoaderStateAction,
    getAllUserOrders: getAllUserOrdersAction,
    getFilteredUserOrders: getFilteredUserOrdersAction,
    updateOrder: updateOrderAction,
    addOrder: addOrderAction,
    doneOrder: doneOrderAction,
    setOrder: setOrderAction,
    getDealComments: getDealCommentsAction,
    addComment: addCommentAction,
    getDealLogs: getDealLogsAction,
    filterUser: filterUserAction,
    getWorkers: getWorkersAction,
    getManager: getManagerAction,
    getUsersByPhone: getUsersByPhoneAction,
    getStorageListByIdArray: getStorageListByIdArrayAction,
    getStorageList: getStorageListAction,
    getComposition: getCompositionAction,
    addSpecialComposition: addSpecialCompositionAction,
    destroySpecialComposition: destroySpecialCompositionAction,
    editUserData: editUserDataAction
  }
)(HomePage);
