import ENV from './Env.js';




export const getList = () => {
  return fetch(ENV.API_URL + '/image/all', {
    method: 'GET',
    headers: ENV.getHeaders(), 
  }).then((response) => {
    return response.json()
  });
}


export const makeImage = (_data) => {
  return fetch(ENV.API_URL + '/image/store', {
    method: 'POST',
    headers: ENV.getHeaders(),
    body: JSON.stringify(_data)
  }).then(function (response) {
    return response.json();
  });
}


 