import React from "react";
import { connect } from "react-redux";

import UiModal from "./UiModal";

import { ReactComponent as LoaderImage } from "../../assets/images/loader/rolling-1s-200px.svg";

function UiLoader(props) {
  return (
    <UiModal name="loader" show={
      props.storage.isFetching  || props.category.isFetching  || props.users.isFetching  
      || props.order.isFetching || props.login.isFetching  || props.show
    }>
      <LoaderImage className="loader-img" />
    </UiModal>
  );
}



export default connect(
  ({ login, storage, users, category, order }) => ({ login, storage, users, category,  order  }),
  {}
)(UiLoader);

