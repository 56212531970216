import React from "react";
import { Route, Navigate } from "react-router-dom";
import * as AuthService from "../../services/Auth";

 
const ProtectedOrgRoute = ({ type, children }) => {
 
  return (AuthService.isAllow(type) === true 
    ? children
    : <Navigate to="/" />);

};

export default ProtectedOrgRoute;
