import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { 
  getManagersList as getManagersListAction,
  addManager as addManagerAction,
  editWorker as editWorkerAction
 } from "../../redux/modules/users";
import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { convertImages } from "../../services/Storage";

import UsersConstant from "../../constants/UserConstant";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalManager from "../../components/modals/UiModalManager";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ManagersStyle.scss";

function ManagersPage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: true, title: "ID" },
    { sort: true, title: "Имя" },
    { sort: true, title: "Email / Логин" },
    { sort: true, title: "Группа" },
    { sort: false, title: "Пароль" },
    { sort: false, title: "Дата регистрации" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListUsers, setTableListUsers] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    if (props.login.user) props.getManagersList();
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.users.usersList.map((item, index) => {
      arr.push([
        { title: clientsTitles[0].title, text: `#${item.id}` },
        {
          title: clientsTitles[1].title,
          text: item.name  ,
        },
        {
          title: clientsTitles[2].title,
          text: item.email  ,
        },
        {
          title: clientsTitles[3].title,
          text: `${UsersConstant.USERS_TYPE_LIST[item.type]}`,
        },
        { title: clientsTitles[4].title, text: item.password },
        {
          title: clientsTitles[5].title,
          text: `${formatDateDMY(item.created_at)} ${formatDateHM(
            item.created_at
          )}`,
        },
        {
          button: "delete", onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.editWorker(_item).then(() => props.getManagersList()  );
            }

          }
        },
      ]);
    });
    setTableListUsers(arr);
  }, [props.users.usersList]);

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Менеджеры" />
        </div>
        <div className="home-info-button">
           
          <UiButtonColor
            color="primary"
            text="Новый менеджер"
            small={true}
            onClick={() =>  setModalActive(true) }
          />
         
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            label="Список пользователей"
            placeholder="Поиск: ФИО или телефон или email"
            onChange={(val) => setSearchLine(val)}
          />
        </div>
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListUsers}
          search={searchLine}
        />
      </section>
      {/* modal */}
      <UiModalManager 
        modalActive={modalActive} 
        modalClose={() =>  setModalActive(false)}
        data={itemData}
        onCreate={(data) => {
          props.addManager(data).then((res) => {
            if(res){
              setModalActive(false)
              props.getManagersList();
            }
          })
        }}
        onEdit={(data) => {
           
          props.editWorker(data).then((res) => {
            if(res){
              setModalActive(false)
              props.getManagersList();
            }
          })
        }}
      />

    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getManagersList: getManagersListAction,
  addManager: addManagerAction,
  editWorker: editWorkerAction
}
)(ManagersPage);
