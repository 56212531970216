import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getWorkers as getWorkersAction,
  addWorker as addWorkerAction,
  editWorker as editWorkerAction
} from "../../redux/modules/users";
import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { convertImages } from "../../services/Storage";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalManager from "../../components/modals/UiModalManager";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ClientsStyle.scss";

function OrgsPage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [
    { sort: false, title: "" },
    { sort: true, title: "ID" },
    { sort: true, title: "Имя" },
    { sort: false, title: "Телефон" },
    { sort: true, title: "Работа" },
    { sort: true, title: "Логин/Пароль" },
    { sort: true, title: "Дата регистрации" },
    { sort: false, title: "Удалить" },
  ];
  const [tableListUsers, setTableListUsers] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    if (props.login.user) props.getWorkers();
  }, [props.login.user]);

  useEffect(() => {
    var arr = [];
    props.users.usersWorker.map((item, index) => {
      console.log(item)
      arr.push([
        {
          title: clientsTitles[0].title,
          text: "",
          button: "open",
          openClick: () => {
            setItemData(item)
            setModalActive(true)
          },
        },
        { title: clientsTitles[1].title, text: `#${item.id}` },
        { title: clientsTitles[2].title, text: item.name ? item.name : "Не указан" },
        {
          title: clientsTitles[3].title,
          text: `+${item.phone}`,
        },
        {
          title: clientsTitles[4].title,
          text: item.deal ? `#${item.deal}` : "свободен",
          textLink: true,
          linkClick: () => {

          },
        },
        { title: clientsTitles[5].title, text: `${item.email} | ${item.password}` },
        { title: clientsTitles[6].title, text: `${formatDateDMY(item.created_at)} ${formatDateHM(item.created_at)}` },
        {
          button: "delete", onClick: () => {
            if (window.confirm("Удалить элемент ?")) {
              let _item = item;
              _item.is_del = item.is_del == 1 ? 0 : 1;
              props.editWorker(_item).then(() => props.getManagersList()  );
            }

          }
        },
      ])
    })
    setTableListUsers(arr)
  }, [props.users.usersWorker])


   function addImages(_id, _imagesList, _oldImages = []) {
    let _arr = [];
    _imagesList.map((item) => {
      let f = false;
      _oldImages.map((item2) => {
        if (item2 == item) f = true;
      })
      if (!f) _arr.push(item);
    })
    if (_oldImages.length == 0) _arr = _imagesList;
    return _arr;
  }

  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Курьеры" />
        </div>
        <div className="home-info-button">
          <UiButtonColor
            color="primary"
            text="Новый работник"
            small={true}
            onClick={() =>  {
              setItemData(null);
              setTimeout(() => setModalActive(true), 100);
            }}
          />
        </div>
      </section>
      <section className="page-content">
        <UiInputSearch
          label="Список пользователей"
          placeholder="Поиск..."
          onChange={(val) => setSearchLine(val)}
        />
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListUsers}
          search={searchLine}
        />
      </section>
      {/* modal deal */}
      <UiModalManager 
        modalActive={modalActive} 
        modalClose={() =>  setModalActive(false)}
        data={itemData}
        onCreate={(data) => { 
          props.addWorker(data).then((res) => {
            if(res){
              setModalActive(false)
              props.getWorkers();
            }
          })
        }}
        onEdit={(data) => {
          data.image =  addImages(data.id, data.image, convertImages(itemData.images));
          console.log(data)
          props.editWorker(data).then((res) => {
            if(res){
              setModalActive(false)
              props.getWorkers();
            }
          })
        }}
      />
    </div>
  );
}

export default connect(({ login, users }) => ({ login, users }), {
  getWorkers: getWorkersAction,
  addWorker: addWorkerAction,
  editWorker: editWorkerAction
})(OrgsPage);
