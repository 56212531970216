import React, { useRef, useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
 

import "./UiSelectDates.scss";

function UiSelectDates(props) {
 
  const [startDate, setStartDate] = useState(new Date());
 
  useEffect(() => {
    if( props.date ) setStartDate(props.date);
  }, [props.date])

  return (
    <div className="select-dates-wrap">
        <DatePicker 
          selected={startDate} 
          dateFormat="dd-MM-yyyy"
          onChange={(date) => { 
            setStartDate(date)
            props.onChange(date)
          }} 
        />
    </div>
  );
}

export default UiSelectDates;
