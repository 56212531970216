import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getFilteredUserOrders as getFilteredUserOrdersAction,
  addOrder as addOrderAction,
  doneOrder as doneOrderAction
} from "../../redux/modules/order";

import { formatDateDMY, formatDateHM } from "../../utilities/date";
import { numberWithCommas } from "../../utilities/numbers";

import OrderConstant from "../../constants/OrderConstant";

import UiInputSearch from "../../components/inputs/UiInputSearch";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiModalUser from "../../components/modals/UiModalUser";
import UiPageText from "../../components/text/UiPageText";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import "./ArchiveStyle.scss";

function ArchivePage(props) {
  const [searchLine, setSearchLine] = useState(null);
  const clientsTitles = [


    { sort: true, title: "Номер" },
    { sort: false, title: "Покупатель" },
    { sort: false, title: "Состав" },
    { sort: true, title: "Статус\nзаказа" },
    { sort: true, title: "Сумма" },
    { sort: false, title: "Оплата" },


    { sort: false, title: "Дата\nдоставки" },
    { sort: false, title: "Адрес\nдоставки" },
    { sort: false, title: "Комментарий" },
    { sort: false, title: "Тип\nоплаты" },
  ];
  const [tableListOrders, setTableListOrders] = useState([]);

  const [modalActive, setModalActive] = useState(false);
  const [itemData, setItemData] = useState(null);

  useEffect(() => {
    getOrders();
  }, [props.login.user]);

  useEffect(() => {
    let arr = [];
    props.order.ordersList.map((item) => {
      if (props.login.user.is_worker == 0 || (props.login.user.is_worker == 1 && props.login.user.id == item.worker_id && item.status < 4)) {
        arr.push([

          { text: `${item.id}` },
          { text: `${item.name ? item.name : ""} +${item.phone}` },
          { text: `${item.values}` },
          props.login.user.is_worker == 0 ?
            {
              statusInfo: OrderConstant.ORDER_TYPES_NAMES[item.status],
              statusColor: OrderConstant.ORDER_TYPES_COLORS[item.status],
              statusList: OrderConstant.ORDER_TYPES_NAMES,
              onStatusChange: (val) => {
                let _item = item;
                _item.status = val;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
              }
            } :
            {
              button: "edit",
              editText: OrderConstant.ORDER_TYPES_MANAGER_NAMES[1],

              openClick: (val) => {
                let _item = item;
                _item.status = 4;
                props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);

              }
            }

          ,

          { text: `${numberWithCommas(item.price)} ₽` },
          {

            payStatus: item.is_pay
              == 0 ? "wait" : "done",
            statusPayList: OrderConstant.ORDER_IS_PAY,
            onStatusPayChange: (val) => {
              let _item = item;
              _item.is_pay = val;
              props.updateOrder(_item, OrderConstant.UPDATE_INFO_CHANGE_INFO);
            }
          },

          { text: item.moving_types == 0 ? `${formatDateDMY(item.date)}  ${(item.time)}` : 'Самовывоз' },
          { text: `${item.address_a ? item.address_a : '-'}` },
          { text: item.text ? item.text : "-" },
          { text: OrderConstant.ORDER_PAYMENTS_TYPES_NAMES[item.payment_type] },
        ])
      }

    })
    setTableListOrders(arr)
  }, [props.order.ordersList]);

  function getOrders(){
    if (props.login.user) props.getFilteredUserOrders("deals.status", ">=", OrderConstant.ORDER_STATUS_DONE, 100);
  }

  function searchNum(line) {
    if (line.length > 0) props.getFilteredUserOrders("deals.id", "=", line, 100); else getOrders();
  }
  function searchPhone(line) {
    if (line.length > 0) props.getFilteredUserOrders("users.phone", "LIKE", `'%${line}%'`, 100); else getOrders();
  }


  return (
    <div id="page" className="home-page">
      <section className="home-info ui-grid">
        <div className="home-info-text">
          <UiPageText title="Архив заказов" />
        </div>
        <div className="home-info-button">
        </div>
      </section>
      <section className="page-content">
        <div className="page-search">
          <UiInputSearch
            placeholder="Поиск: Номер заказа "
            onChange={(val) => searchNum(val)}
          />
          <UiInputSearch
            placeholder="Поиск: Номер телефон"
            onChange={(val) => searchPhone(val)}
          />
        </div>
        <UiTableAdaptive
          titleList={clientsTitles}
          grid={clientsTitles.length}
          tableList={tableListOrders}
          search={searchLine}
        />
      </section>
      {/* modal deal */}

    </div>
  );
}

export default connect(({ login, order }) => ({ login, order }), {
  getFilteredUserOrders: getFilteredUserOrdersAction,
  addOrder: addOrderAction,
  doneOrder: doneOrderAction
})(ArchivePage);
