import React, {useEffect} from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import  { 
  getStoredUser as getStoredUserAction, 
  showUserInfo as showUserInfoAction,
  logoutUser as logoutUserAction,
} from "../../redux/modules/login"
import {
  getCategoryList as getCategoryListAction,
  getSubCategoryList as getSubCategoryListAction,
} from '../../redux/modules/category'
import useWindowDimensions from "../../hooks/useWindowDimensions";

 
 
function UiAnimatedAppLoader(props ) {
  const navigate = useNavigate();
  let location = useLocation().key;
  useEffect(() => { 
    props.getStoredUser(); 
  }, [location, props.state])
 
  useEffect(() => {  
    if( props.login.user){  
      if(props.login.user.api_token && props.login.user.id ){
        props.getCategoryList(); 
        props.showUserInfo( props.login.user.api_token, props.login.user.id ).then((val) => { 
          if(val){
    
          } else {
    
            props.logoutUser().then(() => {
              document.location.href="/";
            });
          }
         }) 
      }
     
    }  
  }, [props.login.user])


  useWindowDimensions( () =>  props.getStoredUser() );
    
    return null;
}

export default connect(
  ({ login, category }) => ({ login, category }),
  {
    getStoredUser : getStoredUserAction, 
    showUserInfo: showUserInfoAction,
    logoutUser: logoutUserAction,
    getSubCategoryList: getSubCategoryListAction,
    getCategoryList: getCategoryListAction
  }
)(UiAnimatedAppLoader);

