import React from "react";

import "./UiPageText.scss";

export default function UiPageText(props) {
  if (props.text) {
    var text = props.text.map((item, index) => {
      return <p key={index}>{item}</p>;
    });
  }

  return (
    <div className="page-text">
      {props.title ? <h3>{props.title}</h3> : null}
      {props.text ? <div className="page-text-wrap">{text}</div> : null}
    </div>
  );
}
