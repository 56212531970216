import React from "react";

import { ReactComponent as SvgBack } from "../../assets/images/ui/arrow-back.svg";

import "./UiButtonColor.scss";

const UiButtonColor = (props) => {
  return (
    <div className="button-wrap">
      <button
        className={
          "button button-blue" +
          (props.color == "orange" ? " button-orange" : "") +
          (props.color == "primary" ? " button-primary" : "") +
          (props.color == "white" ? " button-white" : "") +
          (props.color == "violet" ? " button-violet" : "") +
          (props.color == "brick" ? " button-brick" : "") +
          (props.small ? " small" : "")
        }
        onClick={props.onClick}
      >
        <span>{props.text}</span>
      </button>
    </div>
  );
};

export default UiButtonColor;
