import React, { useState } from "react";

import "./UiInputText.scss";

const UiDropDown = (props) => {
  const [value, setValue] = useState("");

  const items = props.items.map((item, index) => {
    return (
      <option key={index} value={item.value}>
        {item.text}
      </option>
    );
  });
  return (
    <div className="input-wrap">
      {props.label ? <label>{props.label}</label> : null}

      <select
        className={
          "input-text" +
          (props.disabled ? " disabled" : "") +
          (props.info ? " info-active" : "") +
          (props.small ? " small" : "")
        }
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.value);
            setValue(e.target.value);
          }
        }}
      >
        {props.showEmptyVal ? <option value={null}></option> : null}
        {items}
      </select>

      {props.info ? (
        <div className="input-text-info-wrap">
          <p className="input-text-info">{props.info}</p>
        </div>
      ) : null}
      {props.error && value === "" ? (
        <p className="input-text-warn">{props.errorText}</p>
      ) : null}
    </div>
  );
};

export default UiDropDown;
