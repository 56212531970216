
export const saveUser = (_user) => {
  localStorage.setItem("crm_panel_user", JSON.stringify(_user) );
}

export const loadUser = (_user) => {
 let user = localStorage.getItem("crm_panel_user");
 if(user != null && user != undefined) user = JSON.parse(user); 
 return user;
}

export const saveLocation = (_user) => {
  localStorage.setItem("crm_panel_user_location", JSON.stringify(_user) );
}

export const loadLocation = (_user) => {
 let user = localStorage.getItem("crm_panel_user_location");
 if(user != null && user != undefined) user = JSON.parse(user); 
 return user;
}


export const getUID = () => {
  let user = localStorage.getItem("crm_panel_user");
  if(user != null && user != undefined) {
    user = JSON.parse(user); 
    return user.id;
  } else {
    return null;
  }
};


export const isAuthenticated = () => {
  if (localStorage.getItem("crm_panel_user") == null) {
    return false;
  } else {
    return true;
  }
};

export const isAllow = (_t) => {
  let user = localStorage.getItem("crm_panel_user");
  if(user != null && user != undefined) {
    user = JSON.parse(user); 
    if ( _t.includes(user.type ) ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
 
};

 

export const logout = () => {
  if (localStorage.getItem("crm_panel_user") != null) {
    localStorage.removeItem("crm_panel_user");
  }
};
