import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as SvgAvatar } from "../../assets/images/menu/avatar.svg";
import { ReactComponent as SvgBook } from "../../assets/images/menu/book.svg";
import { ReactComponent as SvgBox } from "../../assets/images/menu/box.svg";
import { ReactComponent as SvgCase } from "../../assets/images/menu/case.svg";
import { ReactComponent as SvgSetting } from "../../assets/images/menu/setting.svg";
import { ReactComponent as SvgStatistic } from "../../assets/images/menu/statistic.svg";
import { ReactComponent as SvgTable } from "../../assets/images/menu/table.svg";

import "./UiMenu.scss";

function UiMenu(props) {
  const menuConst = [
    {
      icon: "SvgCase",
      title: "Заказы",
      location: "/"
    },
    {
      icon: "SvgAvatar",
      title: "Клиенты",
      list: [
        {
          title: "Клиенты",
          location: "/clients"
        },
        {
          title: "Менеджеры",
          location: "/managers"
        },
        {
          title: "Курьеры",
          location: "/orgs"
        },
      ]
    },
    {
      icon: "SvgTable",
      title: "Товары",
      list: [
        {
          title: "Все категории",
          location: "/category/all"
        },
        {
          title: "Все товары",
          location: "/products/category/all",
          color: "",
        },
        {
          title: "Создать категорию",
          location: "/add/category"
        },

      ]
    },
    {
      icon: "SvgBook",
      title: "Материалы",
      list: [
        {
          title: "Акции",
          location: "/material/list"
        }, 
      ]
    },
    {
      icon: "SvgBox",
      title: "Архив заказов",
      location: "/archive"
    },
    {
      icon: "SvgStatistic",
      title: "Статистика",
      location: "/statistic"
    },
    {
      icon: "SvgSetting",
      title: "Настройки",
      location: "/setting"
    },
  ];
  const [menuObject, setMenuObject] = useState(menuConst);

  const [subMenuOpenedName, setSubMenuOpenedName] = useState(null);
  let location = useLocation();

  useEffect(() => {
    setSubMenuOpenedName(null)
  }, [location]);

  useEffect(() => {
    if (props.category.list) {
      let obj = menuConst;
      let arr = [];
      props.category.list.map((item) => arr.push({ title: item.title, location: `/products/category/${item.id}` }))
      obj[2].list = obj[2].list.concat(arr);
      setMenuObject(obj);
    }
  }, [props.category.list]);


  function getIcon(_name) {
    switch (_name) {
      case 'SvgAvatar': return <SvgAvatar className="menu-left-item-icon" />
      case 'SvgBook': return <SvgBook className="menu-left-item-icon" />
      case 'SvgBox': return <SvgBox className="menu-left-item-icon" />
      case 'SvgCase': return <SvgCase className="menu-left-item-icon" />
      case 'SvgSetting': return <SvgSetting className="menu-left-item-icon" />
      case 'SvgStatistic': return <SvgStatistic className="menu-left-item-icon" />
      case 'SvgTable': return <SvgTable className="menu-left-item-icon" />
    }
  }

  let menuList = menuObject.map(
    (item, index) => {
      if (item.list) {
        return (
          <div className="menu-left-item-case" key={index}>
            <button
              className={
                "menu-left-item" +
                (location.pathname === item.location || subMenuOpenedName == item.title ? " active" : "")
              }
              onClick={() => {
                setSubMenuOpenedName(subMenuOpenedName != item.title ? item.title : null);
              }}
            >
              <div className="menu-left-item-wrap">
                {getIcon(item.icon)}
              </div>
              <span>{item.title}</span>
            </button>

            {/** Sub Menu */}
            <div className={"menu-left-panel" + (subMenuOpenedName == item.title ? " active" : "")}>
              <div className="menu-left-panel-list">
                {item.list.map((sItem, sIndex) => {
                  return (
                    <Link
                      key={sIndex}
                      className={
                        "menu-left-panel-item" +
                        (location.pathname === item.location ? " active" : "")
                      }
                      to={sItem.location}
                    >
                      <span>{sItem.title}</span>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <Link
            key={index}
            className={
              "menu-left-item" + (location.pathname === item.location ? " active" : "")
            }
            to={item.location}
          >
            <div className="menu-left-item-wrap">
              {getIcon(item.icon)}
            </div>
            <span>{item.title}</span>
          </Link>
        );
      }
    }
  );

  return (
    <div className={"menu-left" + (props.menuActive ? " active" : "")}>
      <div className="menu-left-list">
        {menuList}
      </div>
    </div>
  );
}

export default connect(({ category }) => ({ category }), {})(UiMenu);
