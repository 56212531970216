import { inArray } from "./array";

export const calculateOrder = (_price, _order) => {
    let price = {
        volume_car: [
            1000, 1200, 1500, 1700, 1900, 2100, 2400, 3000
        ],
        movers: [
            0, 700, 1200, 1600
        ],
        movers_additional: 600,
        distance_km: 100,
        distance_less_25_km: 50,
        distance_then_25_km: 40,

        prr_types: [500, 2500],
        trash_value: [1000, 1500, 2000, 3000],
        tak_value: [750, 2000, 2500, 3000, 3000],

        moving_types: [4000, 5000, 3000, 2000, 0],
        unbuild_value: [1500, 2000, 2500],
        pack_value: [500, 700]
    }

    if(_price) price = _price;
    console.log(_price)
    let total = 0;

    //перезд 
    if (_order.moving_types != 4) {
        //переезд
        total = total + price.moving_types[_order.moving_types];
        //Погрузочно-разгрузочные работы
        total = total + price.prr_types[_order.prr_types];
        //Объем машины
        total = total + price.volume_car[_order.volume_car];
        //Люди
        total = total + (_order.movers < 4 ? price.volume_car[_order.movers] : price.volume_car[3] + (_order.movers - 3) * price.movers_additional);
        //Вид такелажа"
        if (inArray(_order.additional_work, 0)) total = total + price.tak_value[_order.tak_value];
        //Вид сборки и разборки
        if (inArray(_order.additional_work, 1)) total = total + price.tak_value[_order.tak_value];
        //Вид упаковки
        if (inArray(_order.additional_work, 2)) total = total + price.pack_value[_order.pack_value];

    } else {
        //вывоз мусора

        //Погрузочно-разгрузочные работы
        total = total + price.prr_types[_order.prr_types];
        //Объем машины
        total = total + price.volume_car[_order.volume_car];
        //Люди
        total = total + (_order.movers < 4 ? price.volume_car[_order.movers] : price.volume_car[3] + (_order.movers - 3) * price.movers_additional);

        //Объем мусора
        total = total + price.trash_value[_order.trash_value];
    }

    //расстояние
    //в городе
    if(_order.address_a_city == _order.address_b_city  ){
        total = total + price.distance_km *  parseInt(_order.distance);
    } else {
        if(parseInt(_order.distance) < 25){
            total = total + price.distance_less_25_km *  parseInt(_order.distance);
        } else {
            total = total + price.distance_then_25_km *  parseInt(_order.distance);
        }
    }
  
  
    return total;
}