import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./UiModal.scss";
import { changeAlertState as changeAlertStateAction } from "../../redux/modules/login";

import UiButtonColor from "../button/UiButtonColor";
import UiModal from "./UiModal";

function UiAlert(props) {
  return (
    <UiModal name="alert" show={props.login.alertShowed}>
      <div className="alert-card">
        <div className="alert-card-title">
          <h3>{props.login.alertTitle}</h3>
        </div>
        <div className="alert-card-text">
          <p>{props.login.alertText}</p>
        </div>
        <div className="alert-card-button">
          <UiButtonColor
            onClick={() => props.changeAlertState(false)}
            text={props.login.alertButton}
            small
          />
        </div>
      </div>
    </UiModal>
  );
}

export default connect(({ login }) => ({ login }), {
  changeAlertState: changeAlertStateAction,
})(UiAlert);
